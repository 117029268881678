import React from "react";

// External

// Internal
import Layout from "../../components/layout";
import SEO from "../../components/SEO";
import { CondexoPayProjectSection } from "../../containers/projects";

const CondexoPayProjectPage = () => {
  return (
    <Layout>
      <SEO
        title="Condexo Pay - Projects"
        description="Simplifying and Securing Online Payment through All-In-One Application for the people of Italy | FinTech Industry | Year: 2019-2020 | Javascript, React"
      />
      <CondexoPayProjectSection />
    </Layout>
  );
};

export default CondexoPayProjectPage;
